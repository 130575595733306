<template>
  <div class="wrapper">
    <div class='description'>
      <div class='label'>标段名称</div>
      <div class='value'>{{detail.name}}</div>
      <div class='label'>开标地点</div>
      <div class='value'>{{detail.bidAddress}}</div>
      <div class='label'>招标方式</div>
      <div class='value1'>
        <DataDictFinder
        dictType="bid_method"
        :dictValue="detail.bidMethod"
          />
      </div>
      <div class='label'>开标日期</div>
      <div class='value2'>{{detail.bidDate}}</div>
    </div>

    <div class='list'>
      <div class='item'>
        <div style='width:44px; flex: none'>序号</div>
        <div style='flex: 3'>
          报名单位
        </div>

        <div class="price">
          <div>报价/万元</div>
        </div>

        <div class="price">
          <div>二次报价/万元(若有)</div>
        </div>

        <div class="switch">
          <div>付款方式是否响应</div>
        </div>

        <div class="switch">
          <div>资信承诺是否符合</div>
        </div>

        <div class="switch">
          <div>供货范围是否符合</div>
        </div>

        <div class="switch">
          <div>商务/技术要求是否符合</div>
        </div>

        <div style='flex: 2'>
          <div style="width:100px;">
            评标汇总意见
          </div>
        </div>
      </div>

      <div v-for="(item, index) in register" :key="item.id">
        <TableItem :item="item" :index="index" @tableInput="tableInput"/>
      </div>
    </div>

      <div class='textarea'>
        评标意见：
        <textarea placeholder='请输入' v-model="juryLeaderRemarks"/>
      </div>

      <div class="center not-print-content">
        <a-space>
          <a-button @click="$close($route.path)">取消</a-button>
          <a-button type="primary" @click="submit" :loading="saveLoading" v-if="!isReview"
            >提交</a-button
          >
          <a-button v-else disabled>审核中</a-button>
        </a-space>
      </div>
  </div>
</template>

<script>
import TableItem from './table-item.vue'
import { mapState } from 'vuex'
export default {
  props:{
    detail:{
      type: Object,
      default: ()=>({})
    }
  },
  components:{
    TableItem
  },
  computed: {
    ...mapState("auth", ["user"]),
    isReview(){
      return this.detail.reviewStatus === 'reviewing';
    },
    register(){
      return Object.assign([], this.detail.registerList)
    },
  },
  data() {
    return {
      recordList: [], // 评标记录
      saveLoading: false,
      juryLeaderRemarks: '' //设初始值
    };
  },
  watch: {
    detail: {
      handler(newV) {
        console.log('bian')
        
        if( Array.isArray(newV.registerList)){
          this.juryLeaderRemarks = this.detail.juryLeaderRemarks || '';
          this.recordList = this.detail.registerList.map(item=>{ //回显评审数据
            return {
              id: item.id,
              formData: {
                id: item.id,
                userId: item.userId,
                isPaymentResponse: item.isPaymentResponse,
                isQualification: item.isQualification,
                isSupplyScope: item.isSupplyScope,
                isTecBusiness: item.isTecBusiness,
                quoteFirst: item.quoteFirst,
                quoteSec: item.quoteSec,
                responseQuestion: item.responseQuestion,
              }
            }
          })
          console.log(this.recordList)
          
        }
      },
      immediate: true, 
      deep: true 
    }
  },

  methods: {
    tableInput(value){
      console.log('tableInput',value)
      // 如有，替换，没有，添加
      let index = this.recordList.findIndex(item=>{
        return item.id == value.id
      })
      
      if(index > -1){
        this.recordList.splice(index,1,value)
      }else{
        this.recordList.push(value)
      }

      console.log('所有选择的',this.recordList)
    },

    submit() {
      let parmas = {
        id: this.detail.id, // 标段id
        juryRemarks: this.juryLeaderRemarks,
        registerList: this.recordList.map(item=>item.formData),
      }

      console.log(parmas);
      this.$emit('send', parmas);
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  .title {
    font-weight: bold;
    text-align: center;
    font-size: 22px;
    padding: 12px 0
  }
}

.description {
  border: 1px solid #e8e8e8;
  border-bottom-width: 0;
  border-right-width: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  .label {
    background: #fafafa;
    padding: 12px;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    text-align:center;
  }
  .value {
    padding: 12px;
    grid-column-start: 2;
    grid-column-end: 5;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    font-weight: bold;
  }
  .value1 {
    padding: 12px;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    font-weight: bold;

  }
  .value2 {
    padding: 12px;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    font-weight: bold;

  }
}

.list {
    border: 1px solid #e8e8e8;
    border-top-width: 0;
    border-bottom-width: 0;
    border-right-width: 0;
  .item {
    display: flex;
    & > div {
      flex: 1;
      padding: 12px 0;
      border-bottom: 1px solid #e8e8e8;
      border-right: 1px solid #e8e8e8;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-weight: bold;
    }
    .price{
      div{
        width: 70px;
      }
    }
    .switch{
      div{
        width: 44px;
      }
    }
  }
  .item:first-child {
    & > div {
      background: #fafafa;
    }
  }
}

input{
  outline: none;
  border: none;
}

.textarea {
  border: 1px solid #e8e8e8;
  border-top-width: 0;
  display: flex;
  align-items: baseline;
    padding: 12px;
  textarea {
    border-width: 0;
    outline: none;
    resize: none;
    flex: 1;
    height: 120px;
  }
}

.center {
  margin-top: 80px;
}
</style>
