<template>
  <div>
    <Pane />

    <div class="container">
      <div id="printContent">
        <div class="header">
          <div class="title">评标结果汇总</div>

          <div class="extra not-print-content">
            <a-space>
              <a-dropdown>
                <a-menu slot="overlay">
                  <template v-for="(item) in judgeList">
                    <a-menu-item :key="item.id">
                      <div class="between" @click="toggleJudges(item)">
                        <span>{{ item.userName }}</span>
                        <a-icon type="close" v-if="!isReview" @click.stop="deleteJudges(item)" />
                      </div>
                    </a-menu-item>
                  </template>

                  <a-menu-item key="1" v-if="!isReview">
                    <UserSelectorDialog
                    title="添加评标委员"
                    :selectedList="judgeList"
                    :disabled="isReview"
                      @change="onSelectJury">
                      <div class="add-center">
                        <a-icon
                        type="plus"
                        style="color: #1890ff; margin-right: 8px"
                        />
                        <span>
                          <span>
                            新增
                          </span>
                        </span>
                      </div>
                    </UserSelectorDialog>
                  </a-menu-item>
                </a-menu>
                <a-button type="primary">
                  查看评标意见 <a-icon type="down" />
                </a-button>
              </a-dropdown>

              <a-dropdown>
                <a-menu slot="overlay">
                  <template v-for="(item) in discipline">
                    <a-menu-item :key="item.id">
                      <div class="between">
                        <span>{{
                          item.userName
                        }}</span>
                        <a-icon type="close" v-if="!isReview" @click="deleteJudges(item)"/>
                      </div>
                    </a-menu-item>
                  </template>

                  <a-menu-item key="1" v-if="!isReview">
                    <UserSelectorDialog 
                      title="添加纪检审计代表"
                      :selectedList="discipline"
                      @change="onSelectJury"
                    >
                      <div class="add-center">
                        <a-icon
                        type="plus"
                        style="color: #1890ff; margin-right: 8px"
                        />
                        <span>
                          <span> 新增 </span>
                        </span>
                      </div>
                    </UserSelectorDialog>
                  </a-menu-item>
                </a-menu>
                <a-button> 纪监审计代表 <a-icon type="down" /> </a-button>
              </a-dropdown>
            </a-space>
          </div>
        </div>

        <div class="row">
          <div class="project-name">
            <span> 项目名称：</span>
            <span> {{this.detail.projectName}} </span>
          </div>

          <div class="print-download not-print-content">
            <span @click="exportFile">
              <a-icon 
                type="table" 
                style="margin-right:5px;color:#1890ff;"
              />导出表格
            </span>
            <span v-print="printObj">
              <a-icon 
                type="printer" 
                style="margin-right:5px;color:#564fc1;"
              />打印页面
            </span>
          </div>
        </div>

      <Table :detail="detail" @send="send"/>
      </div>
    </div>
  </div>
</template>

<script>
import Table from "./components/table";
import UserSelectorDialog from "./components/user-selector-dialog";

import { fetchDetail } from "@/api/bidding";
import { selectUser, removeUser, getJuryList, jurySummary, downloadSummary } from "@/api/bidding/evaluation";

import { saveAs } from "file-saver";
export default {
  components: {
    Table,
    UserSelectorDialog,
  },
  data() {
    return {
      id: "", //标段id
      juryList: [],

      detail: {},

      printObj:{
        id: "printContent",
      },

      isReview: false // 当前表单是否正在审核中,用于控制评委增删
    };
  },
  computed: {
    judgeList() {
      return Array.isArray(this.juryList)
        ? this.juryList.filter((item) => item.role === "jury")
        : [];
    },
    discipline() {
      return Array.isArray(this.juryList)
        ? this.juryList.filter((item) => item.role === "discipline")
        : [];
    }
  },

  mounted() {
    const { query } = this.$route;
    const { id } = query || {};
    if (id) {
      this.id = id;
      this.getDetail(id); // 获取标段详情
      // 获取评委列表-包括纪检审计代表、评标委员 及评标组长评审的数据
      this.getJuryList(id);
    }
  },

  methods: {
    getDetail(id) {
      fetchDetail({ 
        id,
      }).then((res) => {
        this.detail = {...res,juryLeaderRemarks:'',registerList: []};
        this.isReview = this.detail.reviewStatus === 'reviewing';
      });
    },
    getJuryList(id) {
      getJuryList(id).then((res) => {
        this.juryList = res.juryList;
        this.detail.juryLeaderRemarks = res.juryLeaderRemarks;
        this.detail.registerList = res.registerList.map((item) => { // registerList 包含报名单位文件的信息及 评标组长的评审数据
          return {
            id: item.id, // 报名id
            userId: item.userId, //报名单位id
            user: item.user, // 报名单位名称
            isPaymentResponse: item.isPaymentResponse,
            isQualification: item.isQualification,
            isSupplyScope: item.isSupplyScope,
            isTecBusiness: item.isTecBusiness,
            quoteFirst: item.quoteFirst,
            quoteSec: item.quoteSec,
            responseQuestion: item.responseQuestion,
            fileList: [
              {
                name: '商务文件',
                list: item?.filesBusinessList || []
              },
              {
                name: '技术文件',
                list: item?.filesTechList || []
              },
              {
                name: '投标保证金缴纳凭证',
                list: item?.filesBidBondCertList || []
              },
              {
                name: '标书购买凭证',
                list: item?.filesBidBuyCertList || []
              },
              {
                name: '其他文件',
                list: item?.filesOtherList || []
              },
            ],
          };
        });
      });
    },
    toggleJudges(item) {
      console.log(item)
      this.$router.push(`/bidding/index/evaluation/view?id=${this.id}&juryId=${item.id}`);
    },
    deleteJudges(item) {
      const that = this;

      let roleName = "";
      if (item.role === "jury") {
        roleName = "评委";
      } else if (item.role === "discipline") {
        roleName = "纪检审计代表";
      }

      this.$confirm({
        title: `确认要删除${roleName}${item.userName}吗？`,
        onOk() {
          removeUser({
            itemId: item.itemId,
            juryId: item.id,
          }).then(() => {
            console.log("删除成功");
            that.getJuryList(that.id);
          });
        },
      });
    },

    onSelectJury(value) {
      console.log(value);
      this.updateJuryList(value);
    },

    updateJuryList(value) {
      // 更新后台数据
      selectUser({
        juryList: value,
      }).finally(() => {
        this.getJuryList(this.id);
      });
    },

    send(value) {
      console.log(value)
      
      // 校验评委人数是否符合要求
      console.log(this.discipline);
      console.log(this.judgeList);

      if(!this.discipline.length){
        this.$message.error("请选择纪检审计代表！");
        return;
      }
      if(!this.judgeList.length){
        this.$message.error("请选择评标评委！");
        return;
      }
      if (this.discipline.length > 1) {
        this.$message.error("纪检审计代表只能选择一个！");
        return;
      }
      if (this.judgeList.length % 2 === 0) {
        this.$message.error("评标委员人数必须为奇数！");
        return;
      }

      jurySummary(value).then(()=>{
        this.getJuryList(this.id)
      })
    },

    exportFile(){
      downloadSummary(this.id)
      .then((blob) => {
        saveAs(blob, "评标意见表.doc");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 48px 110px;
}

.header {
  text-align: center;
  position: relative;
  margin-bottom: 32px;
  .title {
    font-size: 20px;
    font-weight: 500;
    color: #000000;
  }
  .extra {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
  .project-name {
    font-weight: bold;
    font-size: 16px;

    & > span:last-child {
      color: #1890ff;
    }
  }
  .print-download{
    span:first-child{
      margin-right: 20px;
    }
    span {
      cursor: pointer;
    }
  }
}
</style>