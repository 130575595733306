<template>
<div>
  <div @click="add">
    <slot />
  </div>

  <a-modal
    :title="title"
      :visible="visible"
      @cancel="cancel"
      :footer="null"
      width="400px"
    >
    <a-card class="container">
      <div class="list">
          <UserSelector
            :selectedList="selectedValue"
            @setSelectedList="setSelectedList"
          />
      </div>
      <div class="center">
        <a-space>
          <a-button type="primary" @click="submit"
            >保存</a-button
          >
          <a-button @click="cancel">关闭</a-button>

        </a-space>
      </div>
    </a-card>
  </a-modal>
</div>
</template>
<script>
import UserSelector from "./user-selector-in-dialog";
import {  mapActions } from "vuex";
export default {
  components: {
    UserSelector,
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    selectedList: {
      type: Array,
      default: () => []
    }
  },
  watch:{
    visible(){
      this.selectedValue = this.selectedList
      this.oldList = [...this.selectedList]
    }
  },
  data () {
    return {
      imageTitle: require("@/assets/recruit/icon-hr.png"),
      visible: false,
      selectedValue: [],//未被保存的选中项
      oldList: [], // 修改前的选中项
      id: '',// itemId 标段id
    }
  },
  mounted() {
    console.log('mounted')
    
    const { query } = this.$route;
    const { id } = query || {};
    if (id && this.id !== id) {
      this.id = id;
      this.getList();
    }

  },

  methods: {
    ...mapActions("employeeSelector", ["getList"]),
    add() {
        this.visible = true;
    },
    cancel() {
      this.visible = false;
    },

    setSelectedList(value) { //可以删掉
      console.log('所有选择的', value) // 选择的所有人员
      console.log('以前选择的', this.oldList)
      
      this.selectedValue = value;
    },
    submit() {
      const list = [];
      // 只保留新增的
      this.selectedValue.forEach(item=>{
        // 若委员存在，则不需要添加
        if(this.oldList.findIndex(ele => ele.userId === item.userId) === -1){
          list.push({
            itemId: this.id,
            juryRemarks: '',
            role: this.title === '添加纪检审计代表'? 'discipline': 'jury',
            userId: item.userId,
            userName: item.name
          })
        }
      })
      
      if (list.length === 0) {
        this.$message.error("请选择人员！");
        return;
      }

      this.$emit('change', list)
      this.cancel();
    },
  },
};
</script>

<style lang="less" scoped>

.center{
  margin-top: 20px;
}
.list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  background-color: #fff;
  gap: 1px;

  .item {
    padding: 8px 12px;
    background-color: #fff;

    .title {
      border-bottom: 1px solid #f0f0f0;
      padding-bottom: 12px;
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: bold;

      img {
        height: 16px;
        margin-right: 8px;
      }
    }
  }
}
.add-center{
  text-align: center;
}
</style>