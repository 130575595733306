<template>
<div class="item" id="summay-item">
  <div class="index">{{  index+1 }}</div>
    <div class="user">
      <FileTableDialog
        title="报名文件"
        :msg="item.fileList"
        >
        <span>
          {{item.user}}
        </span>
      </FileTableDialog>
    </div>
    <div >
      <!-- 报价 -->
      <input class="input print-content-line" style="width:50px !important;" v-model="form.quoteFirst"/>
      <a-input-number class="not-print-content" placeholder='请输入' v-model="form.quoteFirst" @change="handleChange"/>
    </div>
    <!-- 二次报价 -->
    <div>
      <input class="input print-content-line" style="width:50px !important;" v-model="form.quoteSec"/>
      <a-input-number class="not-print-content" placeholder='请输入' v-model="form.quoteSec" @change="handleChange"/>
    </div>
    <!-- 付款方式是否响应 -->
    <div>
      <span class="print-content-line">
        <span v-if="form.isPaymentResponse">是</span>
        <span v-else>否</span>
      </span>
      <a-switch class="not-print-content" checked-children="开" un-checked-children="关" v-model="form.isPaymentResponse" @change="handleChange"/>
    </div>
    <!-- 资信承诺是否符合 -->
    <div>
      <span class="print-content-line">
        <span v-if="form.isQualification">是</span>
        <span v-else>否</span>
      </span>
      <a-switch class="not-print-content" checked-children="开" un-checked-children="关" v-model="form.isQualification" @change="handleChange"/>
    </div>
    <!-- 供货范围是否符合 -->
    <div>
      <span class="print-content-line">
        <span v-if="form.isSupplyScope">是</span>
        <span v-else>否</span>
      </span>
      <a-switch class="not-print-content" checked-children="开" un-checked-children="关" v-model="form.isSupplyScope" @change="handleChange"/>
    </div>
    <!-- 商务/技术要求是否符合 -->
    <div>
      <span class="print-content-line">
        <span v-if="form.isTecBusiness">是</span>
        <span v-else>否</span>
      </span>
      <a-switch class="not-print-content" checked-children="开" un-checked-children="关" v-model="form.isTecBusiness" @change="handleChange"/>
    </div>

    <div style='flex: 2'>
      <a-textarea placeholder='请输入' 
      :auto-size="{ minRows: 1 }" 
      v-model="form.responseQuestion" 
      @input="handleChange"/>
    </div>
</div>
</template>
<script>
import FileTableDialog from '@/views/bidding/components/file-table-dialog'

export default {
  components:{
    FileTableDialog
  },
  props:{
    item:{
      type: Object,
      default: ()=>({})
    },
    index:{
      type: Number,
      default:0
    }
  },
  data () {
    return {
      form: { //评标意见表
        id: this.item.id || '',
        userId: this.item.userId || '',
        quoteFirst: this.item.quoteFirst || null,
        quoteSec: this.item.quoteSec || null,
        isPaymentResponse: this.item.isPaymentResponse|| false,
        isQualification: this.item.isQualification|| false,
        isSupplyScope: this.item.isSupplyScope|| false,
        isTecBusiness: this.item.isTecBusiness|| false, 

        responseQuestion: this.item.responseQuestion|| "",//投标单位答疑回复
      },
    }
  },
  methods:{
    handleChange(){
      this.$emit('tableInput',{
        id: this.item.id,
        formData: this.form,
      })
    }
  }
}
</script>

<style lang="less" scoped>
.item {
  display: flex;
  & > div {
    flex: 1;
    padding: 12px 0;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .index{
    width: 44px; 
    flex: 0 0 auto;
  }
  // 公司单位
  .user{
    color: #1890ff;
    flex: 3;
    span{
      cursor: pointer;
    }
  }
  input{
    outline: none;
    border: none;
    text-align: center;
    width: 100px;
  }
  textarea{
    border: none;
    text-align: center;
    width: 100px;
  }

}

.print-content-line{
  display: none;
}

.item :first-child {
  & > div {
    background: #fff;
  }
}
</style>
<style lang="less">
#summay-item{
  .ant-input-number{
    border: none;
    width: 70px;
    .ant-input-number-input{
      text-align: center;
    }
  }
}
</style>